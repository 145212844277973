import {
  ClockIcon,
  AtSymbolIcon,
  Cog6ToothIcon,
  WrenchScrewdriverIcon,
  FolderArrowDownIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Send unlimited emails.',
    description:
      'You can reach out to your subscribers and keep them engaged with your brand, without having to worry about hitting email limits or incurring extra fees.',
    icon: AtSymbolIcon,
  },
  {
    name: 'Ready to use Templates.',
    description:
      'By using our "Ready to use templates" feature, you can save time and effort, while still creating effective email campaigns that drive results.',
    icon: FolderArrowDownIcon,
  },
  {
    name: 'Email scheduling.',
    description:
      "You can schedule your email campaigns to be sent at the optimal time for your subscribers, ensuring that your message is delivered when it's most likely to be seen and engaged with.",
    icon: ClockIcon,
  },
  {
    name: 'Integrate with your favorite tools.',
    description:
      'It offers flexibility and convenience, as you can easily connect your existing tools and platforms to your CampaignHQ account without having to manually import or export data.',
    icon: WrenchScrewdriverIcon,
  },
]

export default function SecondaryFeatures() {
  return (
    <div className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:mx-0">
          <h2 className="text-base font-semibold leading-7 text-indigo-400">
            Everything you need
          </h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Send email campaigns stress-free
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-300">
            CampaignHQ platform is designed to offer peace of mind to email
            marketers, ensuring that their campaigns are delivered successfully
            without any issues.
          </p>
        </div>
        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 text-gray-300 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:gap-x-16">
          {features.map((feature) => (
            <div key={feature.name} className="relative pl-9">
              <dt className="inline font-semibold text-white">
                <feature.icon
                  className="absolute left-1 top-1 h-5 w-5 text-indigo-500"
                  aria-hidden="true"
                />
                {feature.name}
              </dt>{' '}
              <dd className="inline">{feature.description}</dd>
            </div>
          ))}
        </dl>
      </div>
    </div>
  )
}
