import Head from 'next/head'
import Link from 'next/link'
import Image from 'next/image'

import zapierLogo from '@/images/apps/zapier.svg'
import hubspotLogo from '@/images/apps/hubspot.svg'
import shopifyLogo from '@/images/apps/shopify.svg'
import mailchimpLogo from '@/images/apps/mailchimp.svg'
import sendgridLogo from '@/images/apps/sendgrid.svg'
import awsLogo from '@/images/apps/aws.svg'

export default function Integrations() {
  return (
    <div className="bg-white py-16 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-12 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 sm:gap-y-14 lg:mx-0 lg:max-w-none lg:grid-cols-4">
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={awsLogo}
            alt="AWS"
            width={158}
            height={48}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={hubspotLogo}
            alt="Mailchimp"
            width={158}
            height={48}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={zapierLogo}
            alt="Zapier"
            width={158}
            height={48}
          />
          <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={shopifyLogo}
            alt="Shopify"
            width={158}
            height={48}
          />
          
          {/* <Image
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={sendgridLogo}
            alt="Sendgrid"
            width={158}
            height={48}
          /> */}
        </div>
        <div className="mt-16 flex justify-center">
          <p className="relative text-center lg:text-left rounded-full bg-gray-50 px-4 py-1.5 text-sm leading-6 text-gray-600 ring-1 ring-inset ring-gray-900/5">
            <span className="inline">
              We integrate with your favorite apps seamlessly
            </span>
            <a href="/signup" className="font-semibold text-indigo-600">
              <span className="absolute inset-0" aria-hidden="true" /> Try out for free <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
