import {
  MegaphoneIcon,
  FolderIcon,
  Squares2X2Icon,
  CogIcon,
  ClipboardDocumentIcon,
  ForwardIcon,
  ChartBarIcon,
  ArrowPathRoundedSquareIcon,
  CodeBracketIcon,
  ChatBubbleLeftEllipsisIcon
} from '@heroicons/react/24/outline'

export default function Home() {
  return (

    // <div className="relative flex overflow-x-hidden">
    //   <div className="py-12 animate-marquee whitespace-nowrap">
    //     <span className="text-4xl mx-4">Import Contacts</span>
    //     <span className="text-4xl mx-4">Segmentation</span>
    //     <span className="text-4xl mx-4">Campaign Management</span>
    //     <span className="text-4xl mx-4">Automation</span>
    //     <span className="text-4xl mx-4">Analytics</span>
    //     <span className="text-4xl mx-4">Connect your AWS email server</span>
    //   </div>

    //   <div className="absolute top-0 py-12 animate-marquee2 whitespace-nowrap">
    //   <span className="text-4xl mx-4">Import Contacts</span>
    //     <span className="text-4xl mx-4">Segmentation</span>
    //     <span className="text-4xl mx-4">Campaign Management</span>
    //     <span className="text-4xl mx-4">Automation</span>
    //     <span className="text-4xl mx-4">Analytics</span>
    //     <span className="text-4xl mx-4">Connect your AWS email server</span>
    //   </div>
    // </div>
    <div className="grid grid-cols-3 md:grid-cols-6 gap-5 px-10 sm:px-20 relative">
      <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-rose-500 bg-rose-50'>
          <ClipboardDocumentIcon className="h-5 w-5 text-rose-600 " alt="Affordable email marketing tool" data-tooltip-id="email-step"></ClipboardDocumentIcon>
        </div>
        <div className='text-sm sm:text-md text-center font-medium'>
          Email <br /> templates
        </div>
      </div>
      <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-green-500 bg-green-50'>
          <ChartBarIcon className="h-5 w-5 text-green-600" alt="Affordable email marketing tool" data-tooltip-id="email-step"></ChartBarIcon>
        </div>
        <div className='text-sm sm:text-md text-center font-medium'>
          Campaign <br /> analytics
        </div>
      </div>
      <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-orange-500 bg-orange-50'>
          <MegaphoneIcon className="h-5 w-5 text-orange-600" alt="Affordable email marketing tool" data-tooltip-id="email-step"></MegaphoneIcon>
        </div>
        <div className='text-sm sm:text-md text-center font-medium'>
          Campaign <br /> management
        </div>
      </div>
      {/* <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-blue-500 bg-blue-50'>
          <FolderIcon className="h-5 w-5 text-blue-600" data-tooltip-id="email-step"></FolderIcon>
        </div>
        <div className='text-md text-center font-medium'>
          Bulk <br /> imports
        </div>
      </div> */}
      <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-purple-500 bg-purple-50'>
          <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-purple-600" alt="Affordable email marketing tool" data-tooltip-id="email-step" />
        </div>
        <div className='text-sm sm:text-md text-center font-medium'>
          <span className='hidden md:block'>Your personal<br /> </span>  AI assistant
        </div>
      </div>
      <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-yellow-500 bg-yellow-50'>
          <ArrowPathRoundedSquareIcon className="h-5 w-5 text-yellow-600" alt="Affordable email marketing tool" data-tooltip-id="email-step"></ArrowPathRoundedSquareIcon>
        </div>
        <div className='text-sm sm:text-md text-center font-medium'>
          Workflow <br /> automation
        </div>
      </div>
      <div className='flex flex-col items-center space-y-3'>
        <div className='border p-3 rounded-md border-indigo-500 bg-indigo-50'>
          <CodeBracketIcon className="h-5 w-5 text-indigo-600" alt="Affordable email marketing tool" data-tooltip-id="email-step" />
        </div>
        <div className='text-sm sm:text-md text-center font-medium'>
          <span className='hidden md:block'>Other apps &<br /> </span>  API integrations
        </div>
      </div>
    </div>
  )
}
